/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
import {
    CACHE_BREAKER_KEY_PLACEHOLDER,
    ENFORCE_SPA_NAVIGATION_PLACEHOLDER,
    LOAD_ONLY_CURRENT_LOCALE_RESOURCES_PLACEHOLDER,
    TENANT_CONTEXT_PROVIDER_BUNDLE_URL_PLACEHOLDER,
    TENANT_CONTEXT_PROVIDER_RUNTIME_CONFIGURATOR_URL_PLACEHOLDER,
    TENANT_CUSTOM_CODE_BUNDLE_URL_PLACEHOLDER,
    TENANT_DEVICE_TRAITS_BUNDLE_URL_PLACEHOLDER,
    TENANT_DEVICE_TRAITS_ENABLED_PLACEHOLDER,
    TENANT_SPLASH_SCREEN_BUNDLE_URL_PLACEHOLDER,
    TENANT_SPLASH_SCREEN_ENABLED_PLACEHOLDER,
    USE_CACHE_BREAKER_PLACEHOLDER,
    USE_LOADING_INDICATOR_PLACEHOLDER,
    USE_WORKBOX_CACHING_PLACEHOLDER
} from "./sirius.config.constants";

let sirius = (window as any)?.sirius;
if (!sirius) {
    (window as any).sirius = sirius = {config: {}};
}

let siriusConfig = sirius.config;
if (!siriusConfig) {
    (window as any).sirius.config = siriusConfig = {};
}

siriusConfig.tenantContextProviderConfiguratorUrl = TENANT_CONTEXT_PROVIDER_RUNTIME_CONFIGURATOR_URL_PLACEHOLDER;
siriusConfig.tenantContextProviderUrl = TENANT_CONTEXT_PROVIDER_BUNDLE_URL_PLACEHOLDER;
siriusConfig.tenantCustomCodeUrl = TENANT_CUSTOM_CODE_BUNDLE_URL_PLACEHOLDER;
siriusConfig.tenantDeviceTraitsEnabled = TENANT_DEVICE_TRAITS_ENABLED_PLACEHOLDER;
siriusConfig.tenantDeviceTraitsUrl = TENANT_DEVICE_TRAITS_BUNDLE_URL_PLACEHOLDER;
siriusConfig.tenantSplashScreenEnabled = TENANT_SPLASH_SCREEN_ENABLED_PLACEHOLDER;
siriusConfig.tenantSplashScreenUrl = TENANT_SPLASH_SCREEN_BUNDLE_URL_PLACEHOLDER;

siriusConfig.loadOnlyCurrentLocaleResources = LOAD_ONLY_CURRENT_LOCALE_RESOURCES_PLACEHOLDER;
siriusConfig.useLoadingIndicator = USE_LOADING_INDICATOR_PLACEHOLDER;
siriusConfig.enforceSpaNavigation = ENFORCE_SPA_NAVIGATION_PLACEHOLDER;

siriusConfig.useWorkboxCaching = USE_WORKBOX_CACHING_PLACEHOLDER;
siriusConfig.useCacheBreaker = USE_CACHE_BREAKER_PLACEHOLDER;
siriusConfig.cacheBreakerKey = CACHE_BREAKER_KEY_PLACEHOLDER;