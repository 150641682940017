/*
 * Copyright '2024' Dell Inc. or its subsidiaries. All Rights Reserved.
 */
export const TENANT_CONTEXT_PROVIDER_RUNTIME_CONFIGURATOR_URL_PLACEHOLDER = '{{TENANT_CONTEXT_PROVIDER_RUNTIME_CONFIGURATOR_URL_PLACEHOLDER}}';
export const TENANT_CONTEXT_PROVIDER_BUNDLE_URL_PLACEHOLDER = '{{TENANT_CONTEXT_PROVIDER_BUNDLE_URL_PLACEHOLDER}}';
export const TENANT_CUSTOM_CODE_BUNDLE_URL_PLACEHOLDER = '{{TENANT_CUSTOM_CODE_BUNDLE_URL_PLACEHOLDER}}';
export const TENANT_DEVICE_TRAITS_ENABLED_PLACEHOLDER = '{{TENANT_DEVICE_TRAITS_ENABLED_PLACEHOLDER}}';
export const TENANT_DEVICE_TRAITS_BUNDLE_URL_PLACEHOLDER = '{{TENANT_DEVICE_TRAITS_BUNDLE_URL_PLACEHOLDER}}';
export const TENANT_SPLASH_SCREEN_ENABLED_PLACEHOLDER = '{{TENANT_SPLASH_SCREEN_ENABLED_PLACEHOLDER}}';
export const TENANT_SPLASH_SCREEN_BUNDLE_URL_PLACEHOLDER = '{{TENANT_SPLASH_SCREEN_BUNDLE_URL_PLACEHOLDER}}';
export const ENFORCE_SPA_NAVIGATION_PLACEHOLDER = '{{ENFORCE_SPA_NAVIGATION_PLACEHOLDER}}';
export const USE_LOADING_INDICATOR_PLACEHOLDER = '{{USE_LOADING_INDICATOR_PLACEHOLDER}}';
export const LOAD_ONLY_CURRENT_LOCALE_RESOURCES_PLACEHOLDER = '{{LOAD_ONLY_CURRENT_LOCALE_RESOURCES_PLACEHOLDER}}';
export const USE_WORKBOX_CACHING_PLACEHOLDER = '{{USE_WORKBOX_CACHING_PLACEHOLDER}}';
export const USE_CACHE_BREAKER_PLACEHOLDER = '{{USE_CACHE_BREAKER_PLACEHOLDER}}';
export const CACHE_BREAKER_KEY_PLACEHOLDER = '{{CACHE_BREAKER_KEY_PLACEHOLDER}}';